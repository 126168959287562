import './bootstrap';

import { createApp, DefineComponent, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import * as Sentry from '@sentry/vue';
import { ZiggyVue } from 'ziggy-js';

if (document.getElementById('app')) {
    createInertiaApp({
        title: (title) => title,
        resolve: name => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')) as Promise<DefineComponent>,
        setup({ el, App, props, plugin }) {
            const vueApp = createApp({ render: () => h(App, props) })

            if (import.meta.env.VITE_SENTRY_DSN_PUBLIC) {
                Sentry.init({
                    app: vueApp,
                    dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
                    release: import.meta.env.GIT_REVISION,
                });
            }

            vueApp
                .use(plugin)
                .use(ZiggyVue)
                .mount(el);
        },
        progress: {
            color: '#2663EB',
        }
    });
}
